// import React from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import "swiper/swiper-bundle.css"; // Import Swiper styles

// // import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
// const Data4 = () => {
//   const images = [
//     "/images/video.png",
//     "/images/video.png",
//     "/images/video.png",
//     "/images/video.png",
//     "/images/video.png",
//     "/images/video.png",
//   ];
//   return (
//     <>
//       <div className="bg-[#F1EFEC] py-20">
//         <div className="flex justify-center gap-5 flex-col items-center">
//           <span style={{ fontFamily: "Inter" }} className="text-xl">
//             TRAVEL VIDEOS
//           </span>
//           <span
//             style={{ fontFamily: "Playfair Display" }}
//             className="text-4xl font-medium"
//           >
//             Traveling Villages
//           </span>
//         </div>

//         <Swiper
//           spaceBetween={50}
//           slidesPerView={3}
//           navigation
//           pagination={{ clickable: true }}
//           autoplay={{ delay: 2000, reverseDirection: true }}
//           loop
//         >
//           {images.map((image, index) => (
//             <SwiperSlide key={index}>
//               <img
//                 src={image}
//                 className="w-full h-auto"
//                 alt={`Slide ${index + 1}`}
//               />
//             </SwiperSlide>
//           ))}
//         </Swiper>
//         {/* <div className="flex space-x-4">
//   {images.map((image, index) => (
//     <img key={index} src={image} alt={`video ${index}`} className="w-auto h-auto" />
//   ))}
// </div> */}
//       </div>
//     </>
//   );
// };

// export default Data4;










import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
const Data4 = () => {
  const images = [
    "/images/video.png",
    "/images/video.png",
    "/images/video.png",
    "/images/video.png",
    "/images/video.png",
    "/images/video.png",
  ];
  const videoLinks = [
    "https://youtu.be/T8mIcN1_2HI?si=TEJmOoYB5IoYxmBX",
    "https://youtu.be/6UBQvi2vDvI?si=GlH_F3G7oOmqg-AB",
    "https://youtu.be/T8mIcN1_2HI?si=TEJmOoYB5IoYxmBX",
    "https://youtu.be/6UBQvi2vDvI?si=GlH_F3G7oOmqg-AB",
    "https://youtu.be/T8mIcN1_2HI?si=TEJmOoYB5IoYxmBX",
    "https://youtu.be/6UBQvi2vDvI?si=GlH_F3G7oOmqg-AB",
  ];

  return (
    <>
      <div className="bg-[#F1EFEC] py-20">
        <div className="  flex justify-center gap-5 flex-col items-center">
          <span style={{ fontFamily: "Inter" }} className="text-xl">
            TRAVEL VIDEOS
          </span>
          <span
            style={{ fontFamily: "Playfair Display" }}
            className="text-4xl font-medium"
          >
            Traveling Villages
          </span>
        </div>

        <div className="md:mx-20 mt-10">
          <Swiper
            spaceBetween={50}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 2000, reverseDirection: true }}
            loop
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <a href={videoLinks[index]} target="_blank" rel="noopener noreferrer">
                  <img src={image} className="h-auto w-auto object-cover" alt={`Slide ${index + 1}`} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      
      </div>
    </>
  );
};

export default Data4;
