import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';
const Data5 = () => {
    const images = [
        "/images/story.png",
        "/images/video.png",
        "/images/story.png",
        "/images/video.png",
        "/images/story.png",
        "/images/video.png",
      ];
      const videoLinks = [
        "https://youtube.com/shorts/deI7Y_5zewA?si=xtmN6nTypAl1-ACN",
        "https://youtube.com/shorts/PLmbNg2xBzY?si=JHS90GBS-o33vfyj",
        "https://youtube.com/shorts/deI7Y_5zewA?si=xtmN6nTypAl1-ACN",
        "https://youtube.com/shorts/PLmbNg2xBzY?si=JHS90GBS-o33vfyj",
        "https://youtube.com/shorts/deI7Y_5zewA?si=xtmN6nTypAl1-ACN",
        "https://youtube.com/shorts/PLmbNg2xBzY?si=JHS90GBS-o33vfyj",
      ];
  return (
    <div className="bg-[#E9E9E9] py-20">
      <div className="grid grid-cols-12 mx-20">
        <div className="col-span-4  flex flex-col text-black justify-end">
          <span className="font-normal">TRAVEL STORIE</span>
          <span className="text-2xl font-bold my-2">Village Stories</span>
          <span className="mt-5 text-lg">
            Gamda ni safar describes my real experience at wonderful places and
            stays at stunning locations.
          </span>
        </div>
        <div className="col-span-8">
        <Swiper
            spaceBetween={50}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            autoplay={{ delay: 2000, reverseDirection: true }}
            loop
            modules={[Pagination, Navigation, Autoplay]}
            className="mySwiper"
          >
            {images.map((image, index) => (
              <SwiperSlide key={index}>
                <a href={videoLinks[index]} target="_blank" rel="noopener noreferrer">
                  <img src={image} className="h-[500px] w-full object-cover" alt={`Slide ${index + 1}`} />
                </a>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Data5;
