import React from "react";

const data1 = () => {
  return (
    <>
      <div
        style={{
          backgroundImage: 'url("/images/bgmain.png")',
          height: "100vh",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
        className="relative text-white"
      >
        <div className="flex justify-center">
          <div className="absolute top-36 text-4xl ">
            <div className="flex flex-col justify-center items-center gap-20 text-[#FFFFFF] font-normal leading-3 tracking-wider"   style={{ fontFamily: 'Playfair Display' }}
>
              <div className="md:text-3xl text-lg ">'Our Culture Our Heritages'</div>
              <div className="md:text-5xl font-medium text-2xl">Welcome To Gamada Ni Safar</div>
              <div className=" text-center text-xl font-normal md:mx-5 mx-5">The Valuable Attempt to Bring the Beautiful Places and Villages of Gujarat to You is the <br/>"Gamada ni Safar" </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default data1;
