import React from "react";
import Data1 from "./data1";
import Data2 from "./data2";
import Data3 from "./data3";
import Data4 from "./data4";
import Data5 from "./data5";

const Home = () => {
  return (
    <>
      <Data1 />
      <Data2 />
      <Data3 />
      <Data4 />
      <Data5 />
    </>
  );
};

export default Home;
