// import React from "react";

// // import 'swiper/swiper-bundle.min.css';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation } from 'swiper/modules';

// // import SwiperCore, { Navigation, Pagination, Autoplay } from 'swiper';
// const images = [
//   '/images/1.jpg',
//   '/images/2.jpg',
//   '/images/3.jpg',
// ];
// const data2 = () => {
//   return (
//     <div className="bg-[#283A2C] py-24 relative">
//       <div className="text-center text-4xl text-white font-bold">
//         What makes the village different?
//       </div>
//       <div className="grid grid-cols-2 px-36 mt-16">
//         <div>
//           {/* <img src="/images/1.jpg" className="h-[550px] w-[550px] bg-cover " /> */}

//             <Swiper
//             spaceBetween={50}
//             slidesPerView={1}
//             navigation
//             pagination={{ clickable: true }}
//             autoplay={{ delay: 3000, reverseDirection: true }}
//             loop
//           >
//             {images.map((image, index) => (
//               <SwiperSlide key={index}>
//                 <img src={image} className="h-[550px] w-[550px] object-cover" alt={`Slide ${index + 1}`} />
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//         <div className="text-xl text-white">
//           <div>Today's man is moving to towards the city.</div>
//           <div className="mt-2">
//             Due to increasing urbanization, the village culture is slowly dying
//             out. Our culture is the legacy of our forefathers
//           </div>
//           <div className="mt-8">
//             Today I am talking about a small beautiful village where the sound
//             of birds in rivers, dirt roads, lakes, canals, trees, natural
//             surroundings are found.the morning, the cool air, the sun coming out
//             from behind the mountains .the village in is so clean and beautiful
//             and the green environment is seen
//           </div>
//           <div className="mt-8">
//             where the people of the village are seen going to the fields,
//             bullock carts, , some with their goats,some with buffaloes,some with
//             cows.
//           </div>
//           <div className="mt-8 mb-5">
//             On one side, the sound of water from rivers and ponds,where women go
//             to wash their clothes and take their animals to bathe and where
//             small children are many games like hide and seek, panipato,
//             ambapipli, ngoria, tiger goat, langdi, lakhoti playing are seen in
//             the villages.
//           </div>
//         </div>
//       </div>
//       <img src="/images/left.png" className="absolute bottom-0 left-0  h-32 w-fit"/>
//       <img src="/images/right.png" className="absolute bottom-0 right-0  h-32 w-fit"/>
//     </div>
//   );
// };

// export default data2;

// import React from "react";
// import { Swiper, SwiperSlide } from 'swiper/react';
// import { Pagination, Navigation, Autoplay } from 'swiper/modules';
// import 'swiper/css';
// import 'swiper/css/pagination';
// import 'swiper/css/navigation';
// import 'swiper/css/autoplay';

// const images = [
//   '/images/1.jpg',
//   '/images/2.jpg',
//   '/images/3.jpg',
//   '/images/4.jpg',
//   '/images/5.jpg',
//   '/images/6.jpg',
// ];

// const data2 = () => {
//   return (
//     <div className="bg-[#283A2C] py-24 relative">
//       <div className="text-center text-4xl text-white font-bold">
//         What makes the village different?
//       </div>
//       <div className="grid grid-cols-2 px-36 mt-16 gap-10">
//         <div>
//           <Swiper
//             spaceBetween={50}
//             slidesPerView={1}
//             navigation
//             pagination={{ clickable: true }}
//             autoplay={{ delay: 2000, reverseDirection: true }}
//             loop
//             modules={[Pagination, Navigation, Autoplay]}
//             className="mySwiper"
//           >
//             {images.map((image, index) => (
//               <SwiperSlide key={index}>
//                 <img src={image} className="h-[550px] w-full object-cover" alt={`Slide ${index + 1}`} />
//               </SwiperSlide>
//             ))}
//           </Swiper>
//         </div>
//         <div className="text-xl text-white">
//           <div>Today's man is moving towards the city.</div>
//           <div className="mt-2">
//             Due to increasing urbanization, the village culture is slowly dying out. Our culture is the legacy of our forefathers.
//           </div>
//           <div className="mt-8">
//             Today I am talking about a small beautiful village where the sound of birds in rivers, dirt roads, lakes, canals, trees, and natural surroundings are found. The morning, the cool air, the sun coming out from behind the mountains. The village is so clean and beautiful and the green environment is seen.
//           </div>
//           <div className="mt-8">
//             Where the people of the village are seen going to the fields, bullock carts, some with their goats, some with buffaloes, some with cows.
//           </div>
//           <div className="mt-8 mb-5">
//             On one side, the sound of water from rivers and ponds, where women go to wash their clothes and take their animals to bathe, and where small children are playing many games like hide and seek, panipato, ambapipli, ngoria, tiger goat, langdi, lakhoti.
//           </div>
//         </div>
//       </div>
//       <img src="/images/left.png" className="absolute bottom-0 left-0 h-32 w-fit"/>
//       <img src="/images/right.png" className="absolute bottom-0 right-0 h-32 w-fit"/>
//     </div>
//   );
// };

// export default data2;

import React from "react";

const data2 = () => {
  return (
    <div className="bg-[#283A2C] py-24 px-10 relative">
      <div className="text-center text-4xl text-white ">
        <div className="text-base">How is the Village?</div>
        <div className="text-7xl font-light mt-5"> Gamda Ni Safar</div>
      </div>
      <div className="lg:grid lg:grid-cols-12  bg-[#283A2C] py-24">
        <div className="lg:col-span-3 text-white font-thin text-lg" style={{ fontFamily: 'Inter' }}>
          <div >Today's man is moving towards the city.</div>
          <div className="mt-2">
            Due to increasing urbanization, the village culture is slowly dying
            out. Our culture is the legacy of our forefathers
          </div>
          <div className="mt-10">
            Talking about the children of today, small children have started
            using mobile phones to play games inside the mobile itself, due to
            which the physical development of children does not take place.
            Pollution is high in most areas. When children are seen playing in
            the street inside the villages. So that those children are seen to
            develop faster
          </div>
        </div>
        <div className="lg:col-span-6 ml-20 lg:mb-0 lg:mt-0 mb-40 mt-5">
          <div className="relative">
            <img
              src="/images/1.jpg"
              className="w-[303px] h-[475px] -rotate-6 ml-10 "
            />
            <div className="absolute -bottom-32 -left-14">
              <img
                src="/images/6.jpg"
                className="w-[189.13px] h-[263.38px] -rotate-12 "
              />
            </div>
            <div className="absolute -bottom-32 right-24 ">
              <img
                src="/images/3.jpg"
                className="w-[270px] h-[376px] rotate-12 "
              />
            </div>
          </div>
        </div>
        <div className="lg:col-span-3 self-end  text-white text-lg font-normal" style={{ fontFamily: 'Inter' }}>
          <div>
            Today I am talking about a small beautiful village where the sound
            of birds in rivers, dirt roads, lakes, canals, trees, natural
            surroundings are found.the morning, the cool air, the sun coming out
            from behind the mountains .the village in is so clean and beautiful
            and the green environment is seen
          </div>
          <div className="mt-5">
            The people of the village are seen going to the fields, in bullock
            carts, some with their goats, some with buffaloes, and some with
            cows.
          </div>
          <div className="mt-5">
            On one side, the sound of water from rivers and ponds, where women
            go to wash their clothes and take their animals to bathe and where
            small children are many games like hide and seek, Panipat,
            ambapipli, Longoria, tiger goat, lang di, lakhoti playing are seen
            in the
          </div>
        </div>
      </div>
             <img src="/images/left.png" className="absolute bottom-0 left-0 h-28 w-fit"/>
       <img src="/images/right.png" className="absolute bottom-0 right-0 h-32 w-fit"/>
    </div>
  );
};

export default data2;
