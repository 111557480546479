import React from "react";
import { RiMapPin2Fill } from "react-icons/ri";
import { AiOutlineSearch } from "react-icons/ai";

const Data3 = () => {
  return (
    <>
      <div className="bg-[#E9E9E9] py-20 flex justify-center items-center">
        <div className="bg-white rounded-full w-full md:max-w-[1071.61px] h-[86px] px-5 mx-5 md:flex md:items-center md:justify-between ">
          <div className="text-center">
            <div className="text-[#2D3134] text-xl font-semibold">Village</div>
            <div className="text-[#9498A4] flex items-center gap-2">
              <span>Enter Village name</span>
              <span className="text-black">
                <RiMapPin2Fill />
              </span>
            </div>
          </div>
          <div className="text-center">
            <div className="text-[#2D3134] text-xl font-semibold">Taluka</div>
            <div className="text-[#9498A4] flex items-center gap-2">
              <span>Enter Taluka name</span>
              <span className="text-black">
                <RiMapPin2Fill />
              </span>
            </div>
          </div>
          <div className="text-center">
            <div className="text-[#2D3134] text-xl font-semibold">District</div>
            <div className="text-[#9498A4] flex items-center gap-2">
              <span>Enter District name</span>
              <span className="text-black">
                <RiMapPin2Fill />
              </span>
            </div>
          </div>
          <div className="bg-[#283A2C] rounded-full p-3 text-white flex items-center justify-center">
            <AiOutlineSearch className="text-2xl" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Data3;
